<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Modals">
                    <template v-slot:description>
                        <p>import { HbModal } from 'hummingbird-aviary';</p>
                        <p class="pb-0 mb-0">Important Note: There should only ever be 1 "primary" colored button on the bottom action bar of modals. If you see something different in a design mockup, please reach out to the designers or PMs for clarity.</p>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Large / Medium Modal Examples + Code" class="mt-4 mb-6">

            <v-row class="py-4">
                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            title="Modal Window Title"
                            v-model="dialog1"
                            content-padding
                            :force-scroll-to-top.sync="forceScrollToTopSyncer"
                        >
                            <template v-slot:activator>
                                <hb-btn>Open Large Modal</hb-btn>
                            </template>
                            <template v-slot:sticky>
                                <hb-tabs
                                    v-model="activeTab"
                                    background-color="white"
                                    @change="forceScrollToTopSyncer = true"
                                >
                                    <v-tab
                                        :ripple="false"
                                    >
                                        Prototype
                                    </v-tab>
                                    <v-tab
                                        :ripple="false"
                                    >
                                        Examples
                                    </v-tab>
                                </hb-tabs>
                                <v-divider></v-divider>
                            </template>
                            <template v-slot:subheader>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                            </template>
                            <template v-slot:content>
                                <div v-if="activeTab === 0">
                                    Tab 1
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.

                                </div>
                                <div v-else-if="activeTab === 1">
                                    Tab 2
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                    Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                                </div>
                            </template>
                            <template v-slot:left-actions>
                                <hb-link @click="doSomething()">Link</hb-link>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="doSomething()" color="secondary">Third</hb-btn>
                                <hb-btn @click="doSomething()" color="secondary">Second</hb-btn>
                                <hb-btn @click="dialog1 = false">First</hb-btn>
                            </template>
                        </hb-modal>
                    </div>

                </v-col>

                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            size="medium"
                            title="Modal Window Title"
                            v-model="dialog2"
                        >
                            <template v-slot:activator>
                                <hb-btn>Open Medium Modal</hb-btn>
                            </template>
                            <template v-slot:subheader>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                            </template>
                            <template v-slot:content>
                                <div style="height:200px;">&nbsp;</div>
                            </template>
                            <template v-slot:left-actions>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="doSomething()" color="secondary">Second</hb-btn>
                                <hb-btn @click="dialog2 = false">First</hb-btn>
                            </template>
                        </hb-modal>
                    </div>
                    
                </v-col>

            </v-row>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
            <pre class="px-4" :style="codeExpanded1 ? 'height:auto' : 'height:479px;'">
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded1 = !codeExpanded1">
                    {{ codeExpanded1 ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
&lt;hb-modal
    title="Modal Window Title"
    v-model="dialog1"
    content-padding
    :force-scroll-to-top.sync="forceScrollToTopSyncer"
>
    &lt;template v-slot:activator>
        &lt;hb-btn>Open Large Modal&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:sticky>
        &lt;hb-tabs
            v-model="activeTab"
            background-color="white"
            @change="forceScrollToTopSyncer = true"
        >
            &lt;v-tab
                :ripple="false"
            >
                Prototype
            &lt;/v-tab>
            &lt;v-tab
                :ripple="false"
            >
                Examples
            &lt;/v-tab>
        &lt;/hb-tabs>
        &lt;v-divider>&lt;/v-divider>
    &lt;/template>
    &lt;template v-slot:subheader>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div v-if="activeTab === 0">
            Tab 1
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.

        &lt;/div>
        &lt;div v-else-if="activeTab === 1">
            Tab 2
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
            Ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
        &lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
        &lt;hb-link @click="doSomething()">Link&lt;/hb-link>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn @click="doSomething()" color="secondary">Third&lt;/hb-btn>
        &lt;hb-btn @click="doSomething()" color="secondary">Second&lt;/hb-btn>
        &lt;hb-btn @click="dialog1 = false">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>

&lt;hb-modal
    size="medium"
    title="Modal Window Title"
    v-model="dialog2"
>
    &lt;template v-slot:activator>
        &lt;hb-btn>Open Medium Modal&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:subheader>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div style="height:200px;">&nbsp;&lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn @click="doSomething()" color="secondary">Second&lt;/hb-btn>
        &lt;hb-btn @click="dialog2 = false">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>
                </pre>
            </hb-card>
        </hb-card>

        <hb-card title="Confirmation / Delete Examples + Code" class="mt-4 mb-6">

            <v-row class="py-4">
                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            title="Confirm Something"
                            v-model="dialog3"
                            confirmation
                        >
                            <template v-slot:activator>
                                <hb-btn>Open Large Confirmation Example</hb-btn>
                            </template>
                            <template v-slot:subheader>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                            </template>
                            <template v-slot:content>
                                <div style="height:300px;">&nbsp;</div>
                            </template>
                            <template v-slot:left-actions>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="doSomething()" color="secondary">Third</hb-btn>
                                <hb-btn @click="doSomething()" color="secondary">Second</hb-btn>
                                <hb-btn @click="dialog3 = false">First</hb-btn>
                            </template>
                        </hb-modal>
                    </div>

                </v-col>

                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            size="medium"
                            title="Delete Phone Number"
                            v-model="dialog4"
                            confirmation
                        >
                            <template v-slot:activator>
                                <hb-btn color="destructive">Open Medium Delete Example</hb-btn>
                            </template>
                            <template v-slot:content>
                                <div class="py-4 px-6">
                                    Are you sure you want to delete the selected phone number?
                                    <br /><br />
                                    This action cannot be undone.
                                </div>
                            </template>
                            <template v-slot:left-actions>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn color="destructive" @click="dialog4 = false">Delete</hb-btn>
                            </template>
                        </hb-modal>
                    </div>
                    
                </v-col>
                
            </v-row>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
            <pre class="px-4" :style="codeExpanded2 ? 'height:auto' : 'height:479px;'">
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded2 = !codeExpanded2">
                    {{ codeExpanded2 ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
&lt;hb-modal
    title="Confirm Something"
    v-model="dialog3"
    confirmation
>
    &lt;template v-slot:activator>
        &lt;hb-btn>Open Large Confirmation Example&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:subheader>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div style="height:300px;">&nbsp;&lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn @click="doSomething()" color="secondary">Third&lt;/hb-btn>
        &lt;hb-btn @click="doSomething()" color="secondary">Second&lt;/hb-btn>
        &lt;hb-btn @click="dialog3 = false">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>

&lt;hb-modal
    size="medium"
    title="Delete Phone Number"
    v-model="dialog4"
    confirmation
>
    &lt;template v-slot:activator>
        &lt;hb-btn color="destructive">Open Medium Delete Example&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div class="py-4 px-6">
            Are you sure you want to delete the selected phone number?
            &lt;br />&lt;br />
            This action cannot be undone.
        &lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn color="destructive" @click="dialog4 = false">Delete&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Guidance Example + Code" class="mt-4 mb-6">

            <v-row class="py-4">
                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            title="Help Something"
                            v-model="dialog5"
                            guidance
                        >
                            <template v-slot:activator>
                                <hb-btn>Open Large Guidance Example</hb-btn>
                            </template>
                            <template v-slot:subheader>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
                            </template>
                            <template v-slot:content>
                                <div style="height:300px;">&nbsp;</div>
                            </template>
                            <template v-slot:left-actions>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="doSomething()" color="secondary">Third</hb-btn>
                                <hb-btn @click="doSomething()" color="secondary">Second</hb-btn>
                                <hb-btn @click="dialog5 = false">First</hb-btn>
                            </template>
                        </hb-modal>
                    </div>

                </v-col>

                <v-col>
                    <div class="d-flex justify-center">
                        <hb-modal
                            size="medium"
                            title="Help Something"
                            v-model="dialog6"
                            guidance
                            :footer-cancel-option="false"
                        >
                            <template v-slot:activator>
                                <hb-btn>Open Medium Guidance Example</hb-btn>
                            </template>
                            <template v-slot:content>
                                <div class="py-4 px-6">
                                    Here is some guidance for the user:
                                    <br /><br />
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </div>
                            </template>
                            <template v-slot:left-actions>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="dialog6 = false">First</hb-btn>
                            </template>
                        </hb-modal>
                    </div>
                    
                </v-col>
                
            </v-row>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
            <pre class="px-4" :style="codeExpanded3 ? 'height:auto' : 'height:479px;'">
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded3 = !codeExpanded3">
                    {{ codeExpanded2 ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
&lt;hb-modal
    title="Help Something"
    v-model="dialog5"
    guidance
>
    &lt;template v-slot:activator>
        &lt;hb-btn>Open Large Guidance Example&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:subheader>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra vitae ut non sodales adipiscing cras.
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div style="height:300px;">&nbsp;&lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn @click="doSomething()" color="secondary">Third&lt;/hb-btn>
        &lt;hb-btn @click="doSomething()" color="secondary">Second&lt;/hb-btn>
        &lt;hb-btn @click="dialog5 = false">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>

&lt;hb-modal
    size="medium"
    title="Help Something"
    v-model="dialog6"
    guidance
    :footer-cancel-option="false"
>
    &lt;template v-slot:activator>
        &lt;hb-btn>Open Medium Guidance Example&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:content>
        &lt;div class="py-4 px-6">
            Here is some guidance for the user:
            &lt;br />&lt;br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        &lt;/div>
    &lt;/template>
    &lt;template v-slot:left-actions>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn @click="dialog6 = false">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-modal>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="snackbarItem + ' <hb-modal> component code copied to clipboard.'"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemNotifications",
        data: function() {
            return {
                dialog1: false,
                dialog2: false,
                dialog3: false,
                dialog4: false,
                dialog5: false,
                dialog6: false,
                codeExpanded1: false,
                codeExpanded2: false,
                codeExpanded3: false,
                snackbarItem: '',
                success: false,
                activeTab: 0,
                forceScrollToTopSyncer: false,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '200' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'v-model', type: 'boolean', default: 'false', description: 'Set a data property as the v-model with default value of false. When the v-model property value is changed to true, the modal will show. Turn it back to false to close the modal or hit the top right corner "x" close button.' },
                    { name: 'size', type: 'string', default: 'undefined', description: 'Sets the size of the modals. Available sizes are large and medium. Large is the default size and does not need to be set.' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the title text of the modal.' },
                    { name: 'title-icon', type: 'string', default: 'undefined', description: 'Sets the title icon of the modal. Requires mdi-code format.' },
                    { name: 'persistent', type: 'boolean', default: 'true', description: 'If changed to false, this allows the user to close the modal by clicking outside of it.' },
                    { name: 'footer-off', type: 'boolean', default: 'false', description: 'If changed to true, this will turn off the footer/bottom action bar.' },
                    { name: 'show-help-link', type: 'boolean', default: 'true', description: 'If changed to false, this will turn off the HelpJuice icon in the "left-actions" slot.' },
                    { name: 'footer-cancel-option', type: 'boolean', default: 'true', description: 'If changed to false, this will turn off the default footer/bottom action bar cancel option.' },
                    { name: 'close-instead-of-cancel', type: 'boolean', default: 'true', description: 'If changed to true, this will change the text of the footer/bottom action bar cancel option to say "Close" instead of "Cancel". Functionality is the same.' },
                    { name: 'confirmation', type: 'boolean', default: 'false', description: 'If changed to true, applies the confirmation styling such as the yellow border and adding confirmation icon to the title. Use this for confirmation modals or delete modals.' },
                    { name: 'guidance', type: 'boolean', default: 'false', description: 'If changed to true, applies the guidance styling such as the blue border and adding guidance icon to the title. Use this for guidance or assistive/help modals.' },
                    { name: 'no-pre-sub-header', type: 'boolean', default: 'false', description: 'If changed to true, turns of the presubheader description section/slot.' },
                    { name: 'no-sub-header', type: 'boolean', default: 'false', description: 'If changed to true, turns of the subheader description section/slot.' },
                    { name: 'content-padding', type: 'boolean', default: 'false', description: 'If changed to true, this applies "pa-4" (16px of padding on all sides) to the content area.' },
                    { name: 'height', type: 'string', default: 'undefined', description: 'Set the value of this prop to "small", "medium", or "full" to set the max height of the modal. Useful when having a modal with multiple steps or stepper steps within in order to make sure the height does not jump around when moving from step to step. Ask designers which value to use here or whether to just use the default modal height behavior (which is achieved by not using this prop at all).' },
                    { name: 'recalculate-content-position', type: 'boolean', default: 'false', description: 'If set to true, this will manually force a recalculation of the content position. You must ".sync" the prop in order for it to reset correctly every time for subsequent usage as the prop will revert back to false once the function has completed running.' },
                    { name: 'force-scroll-to-top', type: 'boolean', default: 'false', description: 'If set to true, this will manually force the content area to scroll to top. You must ".sync" the prop in order for it to reset correctly every time for subsequent usage as the prop will revert back to false once the function has completed running.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'title', description: 'This slot is used when you need to set some complex or dynamic modal title text and using this slot will override the text set by the "title" prop. Using the "title" prop to set the modal title text is the best method to use in 99% of situations, but the title slot is available if necessary.' },
                    { name: 'activator', description: 'This slot is normally where you place the element that activates the modal when clicked. You can also skip setting this slot and set the v-model of the <hb-modal> to true through another way.' },
                    { name: 'presubheader', description: 'This slot is for subheader description that you want to be placed directly before the sticky slot. This slot will not show unless the user is scrolled to the very top of the content, otherwise it will turn off / not show.' },
                    { name: 'sticky', description: 'This slot is normally where you place HbStepper or HbTabs components to make them sticky which is the requested behavior for these elements by the design team.' },
                    { name: 'subheader', description: 'This slot is for setting the subheader description content.' },
                    { name: 'content', description: 'This slot is setting the main content of the modal.' },
                    { name: 'left-actions', description: 'This slot is for the left side of the bottom action bar, normally where you place a link or a button+icon. Should usually only have 1 action in this slot.' },
                    { name: 'right-actions', description: 'This slot is for setting the primary actions on the right side of the bottom action bar. The spacing/margins are already preset between buttons/elements, so please do not set manual spacing between elements.' }
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@close', description: 'Event for when close icon, cancel link, or close link is clicked.' },
                    { name: '@content-position', description: 'Event for getting the content position and size calcations object of the content of the modal. Then pass this object to the "content-position" prop of the last HbForm in the modal content (also need to set "last" prop for that last HbForm for it to work). This makes sure that the last form in a content modal will always extend down to the bottom of the modal and will recalculate dynamically as contents or browser size changes which is what design wants.' },
                ], 
            }
        },
        methods: {
            doSomething(){
                console.log('Yay. You clicked on something.')
            }
        },
    }
</script>

<style scoped>

</style>